/* eslint-disable no-restricted-globals */
import React from 'react';
import { Link } from 'gatsby';
import { compareIndex } from 'utils/links';
import { Category, StickySidebar } from './style';

export default function DocsList({ categories }) {
  return (
    <StickySidebar>
      {categories.map(category => {
        const products = [...category.products].sort(compareIndex);
        return (
          <Category key={category.name}>
            <h3>{category.name}</h3>
            {products.map(product => {
              const url = product.href || '#';
              return (
                <Link key={product.href} to={url}>
                  <img src={product.logo} alt={product.name} />
                  <span>{product.name}</span>
                </Link>
              );
            })}
          </Category>
        );
      })}
    </StickySidebar>
  );
}
