import React from 'react';

// import HelpLinks from 'components/Docs/HelpLinks';
import {
  DocsListBase,
} from './style';

export default function DocsListContent() {
  return (
    <DocsListBase>
      <h2>GoTo Tech Office</h2>
      <p>
        GoTo&apos;s leadership recognizes that the growing complexity of our technology stack
        has become an unsustainable tax on our productivity, efficiency and our financial bottom line. 
      </p>
      <p>
        The purpose of this Accord is to establish a collaborative effort among the operating companies
        to improve the maturity of our collective technology stack and the efficiency of our engineering
        teams with the ultimate goal of reducing cost and complexity while enabling valuable new capabilities for our users. 
      </p>
      <br />
      {/* <HelpLinks /> */}
    </DocsListBase>
  );
}
