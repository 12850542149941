import styled from 'styled-components';
import { headLG, textMD, headMD, headSM } from 'styles/typography';
import { Link } from 'gatsby';

export const DocsListBase = styled.div`
  border-left: 1px solid rgb(242, 242, 242);
  min-height: 60vh;
  padding: 56px 72px 0 72px;

  h2 {
    ${headLG};
    line-height: 32px;
    letter-spacing: 0.38px;
  }

  p {
    ${textMD};
    padding-top: 12px;
    line-height: 32px;
    line-height: 24px;
    margin: 0;
  }
`;

export const DocsList = styled.div`
  padding: 56px 0 80px 0;
`;

export const DocsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 22px 24px;
`;

export const DocsSection = styled.div`
  h3 {
    ${headMD};
    padding-bottom: 12px;
  }

  & + & {
    margin-top: 56px;
  }
`;

export const DocsCard = styled(Link)`
  border-radius: 4px;
  border: 1px solid rgba(151, 151, 151, 0.2);
  padding: 16px 24px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;

  &:hover {
    border: 1px solid rgb(242, 242, 242);
    box-shadow: 3px 8px 25px 0px rgba(0, 0, 0, 0.05);
  }

  h4 {
    margin: 0;
    ${headSM};
    letter-spacing: 0px;
    line-height: 20px;
  }
  & > div {
    display: flex;
  }
`;
