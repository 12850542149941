import React from 'react';
import Layout from 'layout';
import Container from 'elements/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Sidebar from 'components/Docs/Sidebar/DocList';
import Content from 'components/Docs/DocsListContent';
import { getProductListFromDocs } from 'utils/links';

export default function DocsPage({ location }) {
  const data = useStaticQuery(graphql`
    query DocsList {
      allMarkdownRemark(filter: { fields: { collection: { eq: "docs" } } }) {
        edges {
          node {
            frontmatter {
              title
              category
              logo
              breadcrumb
              toc {
                slug
                title
                items {
                  text
                  slug
                }
              }
              path
            }
            fields {
              slug
              collection
              fileName
              dirName
            }
          }
        }
      }
      meta: googleSheet(
        document: {
          folder: { eq: "" }
          title: { eq: "GoTo Developer Platform" }
          name: { eq: "Docs" }
        }
      ) {
        id
        document {
          content {
            index
            Category_Name
            Logo
            Folder_Name
          }
          title
        }
      }
    }
  `);

  const categories = getProductListFromDocs(
    data.allMarkdownRemark,
    data?.meta?.document?.content
  );

  return (
    <Layout title="Documentations" location={location}>
      <Container style={{ display: 'flex' }}>
        <Sidebar categories={categories} />
        <Content />
      </Container>
    </Layout>
  );
}
